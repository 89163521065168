// @ts-check
/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import firebase from 'firebase/app';

export const db = firebase.firestore();

/** @type {firebase.firestore.CollectionReference<t.User> & { _ref: firebase.firestore.DocumentReference<t.User> }} */
export const userCol = (db.collection('d_user'));

/** @type {firebase.firestore.CollectionReference<t.Like>  & { _ref: firebase.firestore.DocumentReference<t.Like> }} */
export const likeCol = (db.collection('d_like'));

/** @type {firebase.firestore.CollectionReference<t.Post>  & { _ref: firebase.firestore.DocumentReference<t.Post> }} */
export const postCol = (db.collection('d_post'));

/** @type {firebase.firestore.CollectionReference<t.Message>  & { _ref: firebase.firestore.DocumentReference<t.Message> }} */
export const messageCol = (db.collection('d_message'));

/** @type {firebase.firestore.CollectionReference<t.MessageReadLog>  & { _ref: firebase.firestore.DocumentReference<t.MessageReadLog> }} */
export const messageReadLogCol = (db.collection('d_message_read_log'));

/** @type {firebase.firestore.CollectionReference<t.MessageMedium>  & { _ref: firebase.firestore.DocumentReference<t.MessageMedium> }} */
export const messageMediumCol = (db.collection('d_message_medium'));

/** @type {firebase.firestore.CollectionReference<t.Favorite>  & { _ref: firebase.firestore.DocumentReference<t.Favorite> }} */
export const favoriteCol = (db.collection('d_favorite'));

/** @type {firebase.firestore.CollectionReference<t.M_Puzzle>  & { _ref: firebase.firestore.DocumentReference<t.M_Puzzle> }} */
export const puzzleCol = (db.collection('m_puzzle'));

/** @type {firebase.firestore.CollectionReference<t.MailData>} */
export const mailCol = (db.collection('mail'));

export const notificationCol = db.collection('d_notification');

export const notificationReadLogCol = db.collection('d_notification_read_log');

export const getPuzzlePiecesCol = (/** @type {string} */ puzzleId) => {
	const puzzleDoc = db.collection('m_puzzle').doc(puzzleId);

	return /** @type {firebase.firestore.CollectionReference<t.M_PuzzlePiece>  & { _ref: firebase.firestore.DocumentReference<t.M_PuzzlePiece> }} */ (puzzleDoc.collection('m_puzzle_piece'));
};

/** @type {t.utils.ConvertDocument} */
export const convertDocument = doc => {
	if (doc.exists === false) return null;

	return {
		...doc.data(),
		docId: doc.id,
		_ref: doc.ref
	};
};

export const getFieldValue = () => {
	return firebase.firestore.FieldValue;
};

/** @param {Pick<t.Like,'user_id'|'post_id'>} */
export const getLikeDoc = ({ user_id, post_id }) => {
	if (!user_id || !post_id) {
		throw new Error('Invalid argument');
	}
	return `${user_id}-${post_id}`;
};

/** @param {Pick<t.Favorite,'user_id'|'post_id'>} */
export const getFavoriteDoc = ({ user_id, post_id }) => {
	if (!user_id || !post_id) {
		throw new Error('Invalid argument');
	}
	return `${user_id}-${post_id}`;
};

/** @param {Pick<t.Follow,'user_id'|'follow_user_id'>} */
export const getFollowerDoc = ({ user_id, follow_user_id }) => {
	if (!user_id || !follow_user_id) {
		throw new Error('Invalid argument');
	}
	return `${user_id}-${follow_user_id}`;
};

/** @param {Pick<t.MessageReadLog,'user_id'|'message_id'>} */
export const getMessageReadLogDoc = ({ user_id, message_id }) => {
	if (!user_id || !message_id) {
		throw new Error('Invalid argument');
	}
	return `${user_id}-${message_id}`;
};

export const getNotificationDoc = ({ sender_id, user_id, target_id, notification_type }) => {
	if (!sender_id || !user_id || !target_id) {
		throw new Error('Invalid argument');
	}
	return `${sender_id}-${user_id}-${target_id}-${notification_type}`;
};
